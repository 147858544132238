<template>
  <div class="header-wrapper">
    <div v-if="!custom" class="header-content">
      <div class="scenario-container">
        <div class="">
          <bc-icon class="icon" color="#24BBEF">fas fa-layer-group</bc-icon>
        </div>
        <div class="scenario-text">
          <div class="label-scenario">
            {{ $t('application.pages.optimization.scenarioName') }}
          </div>
          <div class="scenario-name">{{ scenario.name }}</div>
        </div>
      </div>
      <div class="period-container">
        <div class="period-wrapper">
          <div class="period">
            {{ $t('application.pages.optimization.period') }}
          </div>
          <div class="date">
            {{ startScenarioDate }}
            <span class="till">{{
              $t('application.pages.optimization.till')
            }}</span>
            {{ endScenarioDate }}
          </div>
        </div>
        <div class="divider"></div>
        <div class="type-container">
          <div>Tipo</div>
          <div class="selected-type">
            {{ scenarioType }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="custom" class="header-content">custom</div>
  </div>
</template>
<script>
import moment from 'moment'
import _ from 'lodash'
import { useUserSettings } from '@cargill/shared'

const { getLanguage } = useUserSettings()

export default {
  name: 'OptimizationHeader',
  data() {
    return {
      format: 'DD MMM YYYY'
    }
  },
  props: {
    custom: {
      type: Boolean,
      default: false
    },
    scenario: {
      type: Object,
      default: new Object()
    }
  },
  computed: {
    startScenarioDate() {
      return this.formatDate(this.scenario.beginHorizon)
    },
    endScenarioDate() {
      return this.formatDate(this.scenario.endHorizon)
    },
    scenarioType() {
      if (_.isString(this.scenario.type)) {
        return this.$t(`application.pages.optimization.${this.scenario.type}`)
      }
      return this.scenario.type.text
    }
  },
  methods: {
    formatDate(date) {
      moment.locale(getLanguage())
      return moment(date).format(this.format)
    }
  }
}
</script>

<style lang="scss" scoped>
.header-wrapper {
  background-color: #1d222d;
  padding: 10px 0;
  font-size: 0.75vw;
  .header-content {
    background-color: #232834;
    padding: 22px 0;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    .scenario-container {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      .icon {
        font-size: 1.5vw;
      }
      .scenario-name {
        color: #24bbef;
        font-weight: bold;
      }

      .scenario-text {
        line-height: 18px;
        .label-scenario {
          color: #959db5;
        }
      }
    }

    .period-container {
      display: flex;
      line-height: 18px;
      margin-right: 15px;
      color: #959db5;
      .period {
        text-transform: uppercase;
      }
      .date {
        color: #24bbef;
        font-weight: bold;
      }
      .till {
        font-weight: normal;
        color: #959db5;
      }
      .divider {
        height: 45px;
        width: 2px;
        margin: 0 10px;
        background-color: #616575;
      }
      .type {
        text-transform: uppercase;
      }
      .type-container {
        .selected-type {
          color: #24bbef;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
