<template>
  <div class="scenario-wrapper" v-if="isReady">
    <div class="create-wrapper">
      <section id="steps">
        <bc-stepper v-model="step" alt-labels class="bg-panel stepper-labels">
          <!-- HEADERS -->
          <bc-stepper-header>
            <bc-stepper-step :complete="step > 1" step="1">
              {{ $t('application.pages.optimization.parameters') }}
            </bc-stepper-step>

            <bc-divider></bc-divider>

            <!--<bc-stepper-step :complete="step > 2" step="2">
              {{ $t('application.pages.scenarioCargill.horizon') }}
            </bc-stepper-step>

            <bc-divider></bc-divider>-->

            <bc-stepper-step :complete="step > 2" step="2">
              {{ $t('application.pages.scenarioCargill.confirmation') }}
            </bc-stepper-step>
          </bc-stepper-header>

          <!-- ITEMS -->
          <bc-stepper-items>
            <bc-stepper-content step="1">
              <v-card class="bg-card">
                <optimization-header
                  :scenario="currentScenario"
                ></optimization-header>
                <div class="card-content">
                  <scenario-parameters ref="parameters"></scenario-parameters>
                </div>
              </v-card>

              <action-buttons
                class="action-buttons"
                :next="$t('application.actions.next')"
                :cancel="$t('application.actions.cancel')"
                @next="validate"
                @cancel="cancel"
              ></action-buttons>
            </bc-stepper-content>

            <bc-stepper-content step="2">
              <div v-if="optimizationIncomplete">
                <v-card
                  class="bg-card"
                  v-show="toggleOptimization && !isBackgroundOptimization"
                >
                  <div
                    v-for="parameterGroup in params"
                    :key="parameterGroup.code"
                  >
                    <div class="card-content">
                      <scenario-parameter-group-component
                        :parameterGroup="parameterGroup"
                        v-if="params"
                        custom
                      />
                    </div>
                  </div>
                </v-card>
                <v-card
                  class="bg-card"
                  v-show="!toggleOptimization && !isBackgroundOptimization"
                >
                  <div class="loading">
                    <bc-loading
                      size="lg"
                      :blockUi="false"
                      v-if="spinnerVisible"
                      hideLabel
                    />
                    <div class="loading__description">
                      <span>{{
                        $t('application.pages.optimization.loadingOptimization')
                      }}</span>
                    </div>
                    <div>
                      <bc-btn
                        color="primary"
                        text
                        @click="promptCancel"
                        :disabled="!hasPermissionToStop"
                        >{{ $t('application.actions.cancel') }}</bc-btn
                      >
                      <bc-btn color="primary" @click="backgroundOptimization">{{
                        $t(
                          'application.pages.optimization.backgroundOptimization'
                        )
                      }}</bc-btn>
                    </div>
                  </div>
                </v-card>
                <v-card v-show="isBackgroundOptimization">
                  <div class="content-container">
                    <div class="message-wrapper">
                      <bc-message
                        type="success"
                        icon="fa-check-square"
                        :title="
                          $t('application.pages.optimization.scenarioQueued')
                        "
                        :subtitle="
                          $t(
                            'application.pages.optimization.scenarioQueuedDescription'
                          )
                        "
                        :actions="[
                          {
                            type: 'button',
                            label: $t(
                              'application.pages.optimization.homepage'
                            ),
                            color: 'link-blue',
                            callback: home
                          },
                          {
                            type: 'button',
                            label: $t(
                              'application.pages.optimization.onGoingTasks'
                            ),
                            color: 'primary',
                            callback: ongoingTaks
                          }
                        ]"
                      >
                      </bc-message>
                    </div>
                  </div>
                </v-card>
              </div>
              <action-buttons
                :next="$t('application.pages.optimization.startOptimization')"
                :cancel="$t('application.actions.cancel')"
                @next="startOptimization"
                @cancel="cancel"
                v-show="toggleOptimization"
              ></action-buttons>
            </bc-stepper-content>
          </bc-stepper-items>
        </bc-stepper>
      </section>
    </div>
    <cargill-confirm-dialog
      v-model="cancelOptimizationDialog"
      type="warning"
      icon="fa-exclamation-circle"
      :title="$t('application.pages.optimization.askCancelOptimization')"
      :subtitle="$t('application.pages.optimization.cancelDescription')"
      :confirmLabel="$t('application.pages.optimization.cancelOptimization')"
      :cancelLabel="$t('application.actions.cancel')"
      :hasCheckbox="false"
      @confirm="cancelOptimization"
      @cancel="cancelOptimizationDialog = false"
    ></cargill-confirm-dialog>
  </div>
</template>

<script>
import {
  useScenarioOpen,
  ActionButtons,
  ScenarioParameters,
  scenarioParametersService,
  ScenarioParameterGroupComponent,
  useUserSettings
} from '@cargill/shared'
import OptimizationHeader from '../components/optimization-header/OptimizationHeader'
import CargillConfirmDialog from '../components/confirm-dialog/CargillConfirmDialog'
import optimizationService from '../api/optimizationService'
import _ from 'lodash'

const { getScenarioOpen } = useScenarioOpen()
const { getUserSettings } = useUserSettings()

export default {
  components: {
    ActionButtons,
    OptimizationHeader,
    ScenarioParameters,
    ScenarioParameterGroupComponent,
    CargillConfirmDialog
  },
  data() {
    return {
      isReady: false,
      step: 1,
      cancelOptimizationDialog: false,
      isBackgroundOptimization: false,
      optimizationIncomplete: true,
      spinnerVisible: true,
      toggleOptimization: true,
      params: null,
      hasPermissionToStop: true,
      form: {
        objectiveFunction: 'max',
        penalties: {},
        generalParameters: {}
      }
    }
  },
  computed: {
    currentScenario() {
      const scenario = getScenarioOpen()
      return scenario
    }
  },
  async created() {
    this.$notification.on(
      'OptimizationUpdateNotification',
      this.optimizationUpdate
    )
  },
  async beforeDestroy() {
    this.$notification.off(
      'OptimizationUpdateNotification',
      this.optimizationUpdate
    )
  },
  async mounted() {
    const currentOptimization = await optimizationService.current()
    this.toggleOptimization = _.isEmpty(currentOptimization)
    if (!this.toggleOptimization) {
      const userSettings = getUserSettings()
      this.hasPermissionToStop =
        userSettings?.id == currentOptimization.requestingUserId
      this.next()
    }
    this.isReady = true
  },
  methods: {
    optimizationUpdate(optimization) {
      if (
        optimization.scenarioId == this.currentScenario.id &&
        optimizationService.isFinished(optimization)
      ) {
        this.home()
      }
    },
    promptCancel() {
      this.cancelOptimizationDialog = true
    },
    async cancelOptimization() {
      const sent = await optimizationService.stopCurrent()
      if (sent) {
        this.home()
      }
    },
    backgroundOptimization() {
      this.isBackgroundOptimization = true
    },
    ongoingTaks() {
      this.$router.push('/ongoingTasks')
    },
    home() {
      this.$router.push('/')
    },
    async validate() {
      const canProceed = this.$refs.parameters.validate()
      if (canProceed) {
        this.params = this.getUpdatedParams()
        await this.updateParams()
        this.next()
      }
    },
    getUpdatedParams() {
      return this.$refs.parameters.getUpdatedGroups()
    },
    async updateParams() {
      scenarioParametersService.updateParameters(this.params)
    },
    startOptimization() {
      this.toggleOptimization = !this.toggleOptimization
      optimizationService.optimize()
    },
    next() {
      window.scrollTo(0, 0)
      this.step++
    },
    back() {
      this.step--
    },
    cancel() {
      this.$router.push('/')
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-stepper__step--active .v-stepper__label,
.v-stepper__step--complete .v-stepper__label,
.theme--dark.v-stepper .v-stepper__label,
.v-stepper__label {
  text-align: center;
}
.card-content {
  background-color: #232834;
  text-transform: uppercase;
  font-size: 0.75vw;
  font-weight: bold;
}
.action-buttons {
  padding: 25px 0;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
  padding: 10rem 0;
  background: #232834;

  &__description {
    margin-top: 20px;

    & > span::after {
      content: ' .';
      animation: dots 1s steps(5, end) infinite;

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.4em 0 0 rgba(0, 0, 0, 0), 0.8em 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: currentColor;
          text-shadow: 0.4em 0 0 rgba(0, 0, 0, 0), 0.8em 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.4em 0 0 currentColor, 0.8em 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.4em 0 0 currentColor, 0.8em 0 0 currentColor;
        }
      }
    }
  }
}
.content-container {
  grid-gap: 15px;
  padding: 20px 0;
  background: #232834;
  padding: 10rem 0;
}
</style>
